import {
  encodeBase64Url,
  decodeCheckoutData,
  decodeStatusData,
  extractIntegrationData,
  extractRestrictedPageId,
  parseAppSectionParams,
  checkoutDataToQuery,
  thankYouDataToQuery,
  integrationDataToQuery,
} from '@wix/pricing-plans-router-utils';
import { ControllerParams } from '@wix/yoshi-flow-editor';
import { SubPage } from '../../../types/common';
import { integrationDataToAppSectionParams } from '../../../utils/integrationData';
import { isDayful } from '../../../utils/is-dayful';

type PageSectionId = 'Checkout' | 'Thank You' | 'Paywall' | 'membership_plan_picker_tpa';

export const PageSectionIdMap: Record<SubPage['name'], PageSectionId> = {
  checkout: 'Checkout',
  status: 'Thank You',
  restricted: 'Paywall',
  list: 'membership_plan_picker_tpa',
};

export class Navigation {
  constructor(
    protected wixCodeApi: ControllerParams['controllerConfig']['wixCodeApi'],
    protected flowAPI: ControllerParams['flowAPI'],
    protected appDefinitionId: string,
    protected readonly hasMultiplePages: boolean,
  ) {}

  get isMultiPageApp() {
    return this.hasMultiplePages;
  }

  async setCurrentPath(subPage: SubPage) {
    const { appDefinitionId, isMultiPageApp } = this;
    const sectionId = isMultiPageApp ? PageSectionIdMap[subPage.name] : 'membership_plan_picker_tpa';
    const path = isMultiPageApp ? subPageToPathMPA(subPage) : subPageToPathSPA(subPage);

    const { relativeUrl } = await this.wixCodeApi.site.getSectionUrl({ appDefinitionId, sectionId });
    this.wixCodeApi.location.to!((relativeUrl ?? '') + path, { disableScrollToTop: true });
  }

  async getCurrentSubPage(): Promise<SubPage> {
    const { location } = this.wixCodeApi;
    const { path } = location;
    if (path[1] === 'payment') {
      return { name: 'checkout', checkoutData: decodeCheckoutData(path[2]) };
    } else if (path[1] === 'status') {
      return {
        name: 'status',
        statusData: decodeStatusData(path[2]),
      };
    } else {
      const params = parseAppSectionParams(location.query?.appSectionParams);
      const pageId = extractRestrictedPageId(params);
      const integrationData = extractIntegrationData(params);
      if (pageId) {
        return { name: 'restricted', pageId, integrationData };
      }
      return {
        name: 'list',
        integrationData: (await isDayful(this.flowAPI, this.wixCodeApi))
          ? { ...integrationData, navigateTo: location.baseUrl + '/_api/dayful/sch/route' }
          : integrationData,
      };
    }
  }
}

function subPageToPathMPA(subPage: SubPage): string {
  const addQueryStringPrefix = (path: string): `?${string}` | '' => (path !== '' ? `?${path}` : '');
  switch (subPage.name) {
    case 'checkout':
      return addQueryStringPrefix(checkoutDataToQuery(subPage.checkoutData).toString());
    case 'status':
      return addQueryStringPrefix(thankYouDataToQuery(subPage.statusData).toString());
    case 'restricted':
      return '/';
    default:
    case 'list':
      return addQueryStringPrefix(integrationDataToQuery(subPage.integrationData).toString());
  }
}

function subPageToPathSPA(subPage: SubPage): string {
  switch (subPage.name) {
    case 'checkout':
      return '/payment/' + encodeBase64Url(subPage.checkoutData);
    case 'status':
      return `/status/${encodeBase64Url(subPage.statusData)}`;
    case 'restricted':
      return '/restricted';
    default:
    case 'list':
      return integrationDataToAppSectionParams(subPage.integrationData);
  }
}
